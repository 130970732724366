<template>
  <div class="container">
    <a-layout   id="components-layout-demo-top-side">
      <a-layout-header class="header">
        <div class="title">
          <div class="logocontent">
            <div class="logimg"><img src="../../static/logo.png" alt=""></div>
            <div><h3>家校共育·中医药文化于劳动技能教育系统课程</h3></div>
          </div>
          <div class="logoutcontent">
            <a-button  size="small" style="align-items: center; border-radius: 0.75rem; font-size: 0.55rem;color:#ffffff;width:3.2rem;height:1.1rem;background: #ADADAD;font-family: font-family: Source Han Sans SC;" @click="logout" class="login-button">退出</a-button>
          </div>

        </div>
      </a-layout-header>
    </a-layout>
    <a-layout-content>
    <div class="videocontent">
      <div class="revert">
        <a-button   style="text-align: center; align-items: center; border-radius: 0.3rem; font-size: 0.77rem;color:#ffffff;width:4.8rem;height:1.4rem;background: #46B872 ;font-family: Source Han Sans SC;" @click="revert()" class="login-button"><a-icon type="left" />返回目录</a-button>
      </div>
      <div class="bofangcontent">
      <div class="bofangqi_xueqi">
    <div class="xueqiimg">
      <img src="../../static/xueqiback.png" />
      <h3>第{{video.TwoclassID}}学期</h3>
    </div>
      </div>
        <div class="bofangqi_title">
          <div class="titlecontent">
            <h3>第{{video.Episode}}讲</h3><h3>{{ video.Name }}</h3>
          </div>

        </div>
        <div class="bofangqi">
          <div class="bofangqi_content">
            <div class="jindu"><img :src="video.Src" /></div>
            <!-- <div class="jinducontent">
              <div :class="video.UrlLevel==1?'jindutitle_active':'jindutitle'"><p>知识讲解</p></div>
              <div :class="video.UrlLevel==2?'jindutitle_active':'jindutitle'"><p>答题</p></div>
              <div :class="video.UrlLevel==3?'jindutitle_active':'jindutitle'"><p>解析</p></div>
              <div :class="video.UrlLevel==4?'jindutitle_active':'jindutitle'"><p>小神农</p></div>
            </div> -->
            <div class="bofangqi_player">
       
              <video-player @ended="next($event)"  style="width: 100%;height: 100%;"  class="video-player vjs-custom-skin"
                             ref="videoPlayer"
                             :playsinline="true"
                             :options="playerOptions"
              ></video-player>
            </div>
          </div>

        </div>
      </div>
    </div>
    </a-layout-content>

    <a-layout-footer style="padding: 0">
      <loginfooter></loginfooter>
    </a-layout-footer>
  </div>
</template>

<script>
import {getItem} from "../../utils/storage";
import loginheader from "../home/components/loginheader";
import loginfooter from "../home/components/loginfooter";
export default {
  name: "classdirectory"
  ,
  components:{
    loginheader:loginheader,
    loginfooter:loginfooter
  },
  data(){
    return {
      isended:false,
      Num:'',
      leveltext:'现在还做个小测试吧',
      levelimg:'../../static/xiaoshennong.png',
      urllevel:0,
      activeindex:0,
      video:null,
      // 视频播放
      playerOptions : {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: "" //url地址
        }],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      },


    }
  },
  mounted() {

    this.Num=this.$route.query.num;

    this.getvideo();
   
  },
  methods:{
    logout(){
      this.$router.push('/newlogin');
    },
    getvideo(){

    let that=this;
      this.$axios.post("/api/Video/Detail",{num:that.Num,userid:getItem("UserID")}).then((res) => {
            if (res.data.Message=="SUCCESS"){
              if(res.data.Data.Url=="")
                that.$message.error("暂无此视频!");
              that.video=res.data.Data;
        
              that.playerOptions.sources[0].src=that.video.Url;
              that.addhistory();
            }
    })
    },

    addhistory(){
      let that=this;

      this.$axios.post("/api/Video/AddLookHisoty",{twoclassid:that.video.TwoclassID,userid:getItem("UserID"),episode:that.video.Episode,urllevel:that.video.UrlLevel}).then((res) => {
        if (res.data.Message=="SUCCESS"){

        }
      })     
    },
    revert(){
      this.$router.push('/classdirectory');
    },
    Tab(vals){
      this.activeindex=vals;
    },
  }
}
</script>
<style>
@media (min-width: 1024px){
  body,html{font-size: 18px!important;}
}
@media (min-width: 1100px) {
  body,html{font-size: 20px!important;}
}
@media (min-width: 1280px) {
  body,html{font-size: 22px!important;}
}
@media (min-width: 1366px) {
  body,html{font-size: 24px!important;}
}
@media (min-width: 1440px) {
  body,html{font-size: 25px!important;}
}
@media (min-width: 1680px) {
  body,html{font-size: 28px!important;}
}
@media (min-width: 1920px) {
  body,html{font-size: 33px!important;}
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header{
  height: 2.76rem;
  background:  linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);

  box-sizing: border-box;

}
.title{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .logocontent{
    display: flex;
    flex-direction: row;
    .logimg{
      width: 3rem;
      height: 2.2rem;
      margin-top: 0.2rem;
      img{
        width: 100%;
        height: 100%;
      }
    }
    h3{
      padding-top: 0.1rem;
      margin-left: 0.6rem;
      color: #46B872;
      font-size: 0.77rem;
      align-items: center;
      vertical-align: center;

    }
  }

}
.videocontent{
  width: 35rem;
  margin: 0.6rem auto 1.3rem;
  .revert{
    height: 3rem;
  }
  .bofangcontent{
  height: 22rem;
    width: 100%;
    box-shadow: 0px 0px 18px 0px rgba(8, 68, 31, 0.26);
    border-radius: 0.6rem;
    .bofangqi_xueqi{


      .xueqiimg{
        width: 7.2rem;
        height: 1.9rem;
        position: relative;
      img{
        width: 100%;
        height: 100%;
      }
        h3{
          color: #ffffff;
          font-size: 0.8rem;
          padding-top: 0.2rem;
          text-align: center;
          position: absolute;
          top: 0;
          left:1rem;
        }
      }


    }
    .bofangqi_title{
      height: 4rem;
      .titlecontent{
        height: 100%;
      margin-top: 1rem;
        display: flex;
        flex-direction: row;
        width: 12rem;
        justify-content: space-between;
        margin-left: 1.5rem;
        h3{
          font-family: SourceHanSansSC;
          font-weight: bold;
          color: #333333;
          font-size: 0.77rem;
        }
      }

    }
    .bofangqi{

      margin-left: 1.5rem;
      .bofangqi_content{
        display: flex;
        flex-direction: row;
        .jindu{
          margin-left: 0.5;
          height: 12rem;
          img{
            height: 100%;
          }
        }
        .jinducontent{
          height: 12.6rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 1rem;
          .jindutitle{
            p{
              font-size: 0.77rem;
              color: #333333;
            }
          }
          .jindutitle_active{
            p{
              font-size: 0.77rem;
              color: #46B872;
            }
          }
        }
        .bofangqi_player{
          margin-left: 4rem;
          width: 22rem;
          position: relative;
          z-index: 1;
          .zz{
            width: 100%;
            height: 100%;
            background: black;
            opacity: 0.5;
            position: absolute;
            z-index: 2;
          }
          .bofangqi_zz{

            height:6.7rem;
            width: 7.9rem;
            position: absolute;
            z-index: 5;
            display: flex;
            flex-direction: column;
            top: 20%;
            left: 33%;
            .zzimg{
              height: 6rem;
              img{
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

    }
  }
}
::v-deep .ant-btn > .anticon{
  margin-left: -0.3rem;
}


</style>